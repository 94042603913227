.card-bordered{border-radius: 5px; margin-left: 9px;}
.is-light .nk-menu-link, .nk-menu-icon, .nk-tb-list{color: #3d3d3d}
.nk-tb-head {background: #3b82f6!important; border: 0}
.nk-tb-head .sub-text{color: #fff}
.custom-stats{border-radius: 0px}
.custom-stats .nk-tb-list{padding: 0 0}
.nk-tb-head .nk-tb-col{color: #fff}
.text-muted {color: #213248 !important;}
.nk-tb-col{padding: .4rem 0.5rem;}
.f-table{background: #fff !important;}
.custom-strip:nth-child(2n+1) {background: #fff2f2;}
.sub-text{color: #6f6f70}
.cmr-2{margin-right: 20px}
.c-text-dark{color: rgb(100, 99, 99)}
.bg-custom{background: #f2b453}
.bg-theme{ background: #3b82f6;}
.modal-header{background: #3b82f6;}
.modal-header .modal-title {color: #fff}
.modal-header .close{color: #fff; opacity: 1;}
.modal-header{padding: .6rem 1.5rem;}
.nk-reply-form-header{padding: 0 .8rem;}
.custom-border{border: 1px solid #bbb;}
.nk-reply-form-header{border-bottom: 0px}
.nk-reply-form-tools{border-top: none; padding-left: 0px !important}
textarea::placeholder, input::placeholder{color: #727373 !important;}
.h-25{height:25px}
.css-yk16xz-control{border-radius:0 !important}
.nk-reply-form-field:not(:last-child){border-bottom: 1px solid #bbb;}
.react-select__control{border: 1px solid #bbb !important;}
.nk-reply-form-group{padding: .7rem 0;}
.nk-reply-form-field{padding: 0.5rem .6rem;}
.lead-text{color: #3c3c3d;}
.nk-reply-header .nk-reply-desc{color: #c7c7c7;}
.nk-reply-avatar{background:yellow}
.c-l-border{border-left: 3px solid #3b82f6;}
.c-p-5{padding: .5rem}
.c-p-1{padding: 1rem}
.card-header{background-color: rgb(59 130 246);color: #fff;}
.tb-tnx-item td{padding: .5rem 0.25rem;}
.tb-tnx-head{background: #3b82f6;}
.tb-tnx-head th{color: #fff;}
button .ni-edit-alt{font-size: inherit !important}
.overline-title{letter-spacing: 1px;color: #2d2d2d;}
.nav-tabs .nav-link{padding: .6rem 0;}
.overline-title-alt{letter-spacing: .06rem;}
.custom-info .data-label{font-weight: bold; color: #000}
.link-list-menu a, .link-list-menu a .icon{color: #242424;}
.table {color: #313131;}
.overline-title{font-size: 12px;}
.c-campaign .icon{color: #3b82f6}
.pro2-msg::after{border-color: transparent #3b82f6;}
.pro2-msg{background-color: #3b82f6;}
.img-outer{border: 2px solid #3b82f6;}
.nk-wizard-simple .steps ul li h5{color: #444445;}
.nk-footer-copyright {color: #5e5e5e;}
.text-soft {color: #545455 !important}
.nk-block-des {color: #4f5050;}
.badge-dim.badge-success{color: #0e936f;}
.badge-dim.badge-warning {color: #ca9e10;}
.nk-tb-item:not(:last-child) .nk-tb-col {border-bottom: 1px solid #ebebeb;}
.overline-title-alt{color: #515151;}
.text-white{color:#fff}
.invoice-no{padding: 8px 8px 8px 30px;}
.c-text-light{color: #757575 !important;}


.hdfont{font-size: 19px;}
.btntop{margin-right: 50px;}
.sm-drop-zone3 {width: 100%;}
.custom-control-pro.custom-control-sm .custom-control-label {padding: 0.61rem 0.525rem 0.61rem 2.5rem;font-size: 12px;line-height: 1.25rem;border-radius: 4px;}
.table .thead-light th {color: #ffffff;background-color: #3b82f6;border-color: #3b82f6;}
.table .thead-light th .overline-title{color:#fff;}
.btn-trigger.active:hover:before {background-color: #1a67e6!important;}
.newreq{margin-right: 15px;}
.sm-tbl td, .sm-tbl th {padding: 9px 5px;}
.card-title .subtitle{text-align: justify;}
.balleft {line-height: 1.2; font-size: 12px; text-align: left;  margin-left: 30px;}
.webboxcard{align-items: center;display: flex ;justify-content: center;}
.accordion-head {padding: 1rem 1.2rem 1rem 1.25rem;display: block;position: relative;}
.card-title p {font-size: 12px;color: #516c8f!important;}
.repcolor .nk-tb-head .nk-tb-col {color: #fff !important;}
.repcolor .nk-tb-head .nk-tb-col .sm-ico-sort {color: #ffffff;}
.notify-custom {position: absolute; top: 4px; left: 12px; height: 13px; width: 20px; justify-content: center; align-items: center; font-size: 9px; background-color: #c70000; border: none;}
.modal-open .modal {overflow-x: auto!important;}
.scrollweb{overflow-y:scroll; height:240px}
.custom-info .data-item { display: block!important;}   
.clipboard-init{background: #3b82f6 !important; color:#fff !important;}
.nk-body.modal-open{padding-right: 0px!important;}
.card-bordered .card-aside {min-height: auto!important;}
.data-item .break-word { overflow-wrap: break-word; }
.word-wrap {width:441px; }
.btn-default {background: #ededed;border: 1px solid #ccc;}
.btn-default:hover {background: #cdcdcd;border: 1px solid #ccc;color: #000;}
.nk-quick-nav-icon {width: 40px;height: 40px;padding-top: 15px;}
.btn-sm, .btn-group-sm > .btn, .dual-listbox .btn-group-sm > .dual-listbox__button {padding: 0.25rem 0.55rem;font-size: 0.65rem;}
.modal-content button.btn.btn-primary, .btn-default.btn-lg {padding: 8px 10px;}
body.nk-body.bg-lighter.npc-default.has-sidebar.no-touch.nk-nio-theme.modal-open {overflow: hidden!important;}
.modal-content .modal-body { padding: 0.5rem 0.5rem!important;}
.data-item .break-word { overflow-wrap: break-word; }
.word-wrap { width: 450px; padding-right: 20px;}
.hdcolor{ color: #3b82f6; font-weight: 600;}
.card-bordered-new { box-shadow: 0px 0px 10px #a4caff; padding: 15px; border: 1px solid #e3efff;}
.card-bordered-ne button.btn-block {font-size: 1.2rem;}
.listcont{list-style: decimal; margin-left: 15px;padding-left: 15px;}
.orderList{list-style: decimal; margin-left: 15px;padding-left: 15px;}
.orderList li::marker {font-weight:600}
.customBg .modal-title p{ font-size: 16px; }
.deleteAccount{ padding: 0.25rem 0.75rem;font-size: 0.75rem;}
.suprt-reply .alert:last-child {margin-bottom: 50px !important;}
@media (max-width: 575px) {
    .page-link {min-width: calc(0.8rem + 1rem + 0px);}
    .card-inner .dropdown a.dropdown-toggle {height: 36px;}
    .nk-header-brand .logo-img{width: 100%; display: none;}
    /* .nk-header-tools .nk-quick-nav > li {padding: 0 6px;} */
    .hdfont{font-size: 12px;}
    .nk-quick-nav > li.btntop {padding: 0 3px;width: 66px; margin-right: 0px;}
    .nk-menu-trigger {margin-right: 0.17rem;}
    .btntop a.btn-sm {padding: 0.25rem 0.45rem; font-size: 0.55rem; line-height: .85rem;}
    .form-label {font-size: 0.852rem;}
    .nk-quick-nav-icon {font-size: 1.3rem; padding: 0.275rem;}
    .user-avatar-sm, .user-avatar.sm { height: 26px; width: 26px;font-size: 12px;}
    .card-title .paybtn{text-align: center;}
    .sm-drop-zone3 {width: 100%;}
    .toggle-wrap .btn-trigger{background: #3b82f6; color: #fff;border-radius: 50px;}
    .nk-block-tools {display: block;align-items: center;}
    .newreq{margin-right: 2px; padding: 3px;font-size: 12px;}
    .newreq span{padding-left: 2px!important;}
    .newreq .icon {font-size: 1.2em; margin-left: 0px!important;}
    .icnhed .btn-icon .icon, .dt-buttons .btn-secondary .icon {width: 1.125rem;}
    .nk-ibx-item-fluid {padding-left: 0px;}
    .tbtn{background: #3b82f6; color: #fff; border-radius: 50px;}
    .conttrf{display: block!important; margin: 2px;}
    .conttrf .btn-sm{padding: 0.25rem 0.15rem;}
    .form-control, .rdl-filter, .dual-listbox .dual-listbox__search, div.dataTables_wrapper div.dataTables_filter input {padding: 0.4375rem 0.5rem;font-size: 0.8125rem;}
    .nk-footer .nk-footer-wrap {text-align: center; display: block;}
    .nk-footer .nav-link {padding: 0.5rem 0.5rem!important;}
    .nk-footer li.nav-item {text-align: center;margin: 0 auto;}
    .sm-tbl td, .sm-tbl th {padding: 9px 7px!important; float: left;}
    .icnhed ul li h5{font-size: 13px;}
    .compinbos {margin-top: 9px; padding-left:0px!important;}
    .nk-footer-copyright {margin-top: 15px;background: #3b82f6; color: #fff; padding: 5px;}
    .walcard .tb-tnx-amount.is-alt {position: absolute;display: inline-flex;right: 10px;width: 200px;}
    .balleft {line-height: 1.2; font-size: 12px; text-align: left;  margin-left: 2px;}
    .mwbtn{padding: 0.15rem 0.25rem;font-size: 0.65rem;line-height: .87rem;border-radius: 3px;}
    .webboxcard{display: block!important; align-items:left!important; justify-content: left !important;}
    .reportbox{overflow: auto;margin:10px;padding:0px;}
    .paycard{width: 100%;}
    button.btn.toggle.btn.btn-icon.btn-trigger.mt-n1 {background: #3b82f6; color: #fff;border-radius: 50px; }
    .nk-footer-copyright-login {margin-top: 5px;background: #3b82f6; color: #fff !important; padding: 5px; font-size: 13px;text-align: center;}
    .nk-footer-copyright-login a {color: #fff !important;}
    .word-wrap {width: 250px;}
}
.metaBox pre.bg-lighter {font-size: 15px;}
.metaBox .metaNoteText {font-size: 15px;margin-top: 0.75rem;}
@media only screen and (min-width: 761px) and (max-width: 1730px) { .word-wrap { width: 335px; padding-right: 20px;}}
.feedback-rating-stars {margin-bottom: 3px; color: black;}
.feedback-btn {background-color: #0377d0;; border-color: #0377d0;; color: #fff}
.feedback-btn:hover {background: #0466b0; color: #fff;}
.nk-reply-form-actions a#ibx-msg-attachment {
    background: #ebebeb;
    padding: 8px 20px 8px 5px;
}
.boxscroll {
    overflow-x: auto;
}
.wrd-break{
    overflow-wrap: break-word;
    width: 210px;
    display: block;
}
.agent-manager-card {
    background: #ffffff;
    margin-right: 12px;
    /* box-shadow: 0px 0px 10px #ffbfbf; */
}
.agent-manager-heading {
    font-size: small;
    background: #f4f9ff;
    color: #3b82f6;
    padding: 10px;
    text-align: center;
    border-radius: 31px;
}
.agent-manager-name {
    justify-content: center;
    font-size: 17px;
    color: #3d3d3d;
}
.agent-manager-contact {
    white-space: nowrap;
    font-size: 13px;
    display: block;
}
/* .agent-manager-contact-scroll {
    overflow: auto;
    padding-bottom: 6px;
} */
.email-wrap-custom {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.slider-header-wrap-custom > * {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.calendly-meet-btn-custom {
    background-color: #0377d0;
    border-color: #0377d0;
    color: #fff;
}
.calendly-meet-btn-custom:hover {
    background-color: #0466b0;
    border-color: #0377d0;
    color: #fff;
}

.btn-primary {
    color: #fff;
    background-color: #3b82f6;
    border-color: #3b82f6;
}
.btn-primary:hover {
    background-color: #1a67e6;
    border-color: #1a67e6;
}
.bg-lighter, .rdl-control > option:hover {
    background-color: #f4f9ff !important;
}
/* .alert.alert-danger {
    color: #ff0000;
    background-color: #fff8f8;
    border-color:#fad8d8;
} */
footer {
    background: #fff;
}
.text-danger {
    color: #F44336 !important;
}
.ni-facebook-fill{color:#3B5998;}
.ni-twitter-round{color:#55ACEE;}
.ni-instagram-round{color:#cc2366; }
.ni-linkedin-round{color:#007bb5;}
.ni-pinterest-round{color:#cb2027;}
.ni-youtube-round{color:#bb0000;}
.nk-activity-data .badge-danger {
    border-color: #c70000!important;
    background: #c70000!important;
}
.nk-ibx-context-badges .badge-danger {
    border-color: #c70000!important;
    background: #c70000!important;
    color: #fff;
}
.nk-notification-content .badge-danger {
    border-color: #c70000!important;
    background: #c70000!important;
    color: #fff!important;
}
.analytic-ov .analytic-data .text-danger {
    color: #ff7f0e !important;
}
.webboxcard .badge-dim.badge-danger {
    border-color: #c84444 !important;
    background: #c84444 !important;
    color: #ffffff !important;
}
.nk-ibx-reply-head .badge-danger {
    border-color: #c70000!important;
    background: #c70000!important;
    color: #fff!important;
}
.lgbox .alert.alert-danger {
    background-color: #fff8f8;
    border-color: #fad8d8;
    color: red;
}
.file-upload-card {
    margin-top: 6%;
}
.file-preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.file-image {
    height: 120px;
    width: 120px;
    object-fit: cover;
}
.file-remove-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.upload-button {
    display: inline-block;
    width: 120px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0;
}
.upload-button input {
    display: none;
    margin-top: 0px;

}
.payment-button{
    margin-left: 9px;
}